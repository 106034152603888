import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { Fragment } from 'react';
import { BreadCrumbSection, Loader } from '../../components';
import ReportsNavbar from './components/ReportsNavbar/ReportsNavbar';
import './_Reports.scss';

const GET_ACCOUNTS_REPORT_URL = gql`
  query MP_GetAccountsReportUrl {
    getAccountsReportUrl {
      iframeUrl
      dashboard
    }
  }
`;

const MetabaseAccountsReport = () => {
  const { data: accountsReportData, loading: loadingAccountsReport } = useQuery(
    GET_ACCOUNTS_REPORT_URL,
  );

  return (
    <Fragment>
      <BreadCrumbSection />
      <ReportsNavbar />

      {loadingAccountsReport && <Loader />}

      <div className="metabase-wrapper">
        {accountsReportData && (
          <iframe
            className="iframe"
            src={`${accountsReportData?.getAccountsReportUrl?.iframeUrl}#bordered=false&titled=true`}
            frameBorder="0"
            width="100%"
            height="600px"
          />
        )}
      </div>
    </Fragment>
  );
};

export default MetabaseAccountsReport;
