import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getUserFullDetails, hasPermissions } from '../../../containers/Config';
import {
  VIEW_ACCOUNTS_REPORT,
  VIEW_APPLICATIONS_REPORT,
  VIEW_CUSTOMERS_TAB,
  VIEW_DASHBOARD_TAB,
  VIEW_LIQUIDITY_MGT_TAB,
  VIEW_LOANS_REPORT,
  VIEW_LOAN_TAB,
  VIEW_POLICY_TAB,
  VIEW_REPAYMENTS_REPORT,
  VIEW_SETTINGS_TAB,
} from '../../../containers/Config/constants';
import { ExternalLinkIcon } from '../../Icons';
import ExternalIcon from '../../Icons/ExternalIcon';
import './_HeaderNavs.scss';

const HeaderNavs = () => {
  const userDetails = getUserFullDetails();
  const location = useLocation();

  // this is needed to force login incase user is on old code and no email in localstorage
  if (!userDetails || typeof userDetails === 'undefined') {
    window.location.href = '/logout';
  }
  const userPermissions = userDetails.permissions || [];
  const isActiveLink = (match, location) => {
    return (
      location.pathname === '/loans' ||
      location.pathname === '/loans/applications'
    );
  };

  return (
    <nav className={'header-navs'}>
      {/* {hasPermissions(VIEW_DASHBOARD_TAB, userPermissions) && ( */}
      <NavLink to="/v1/dashboard">Dashboard</NavLink>
      {/* )} */}
      {hasPermissions(VIEW_LOAN_TAB, userPermissions) && (
        <NavLink
          to="/v1/applications"
          data-testid="loans-menu"
          isActive={isActiveLink}
        >
          Loans
        </NavLink>
      )}
      {hasPermissions(VIEW_CUSTOMERS_TAB, userPermissions) && (
        <NavLink to="/v1/customers" data-testid="customers-menu">
          Customers
        </NavLink>
      )}

      <NavLink to="/v1/collections" data-testid="collections-menu">
        Collections
      </NavLink>

      {hasPermissions(VIEW_POLICY_TAB, userPermissions) && (
        <NavLink to="/v1/loan-products" data-testid="loanProducts-menu">
          Loan Products
        </NavLink>
      )}
      {hasPermissions(VIEW_SETTINGS_TAB, userPermissions) && (
        <NavLink to="/settings" data-testid="settings-menu">
          Settings
        </NavLink>
      )}

      {hasPermissions(VIEW_APPLICATIONS_REPORT, userPermissions) ||
      hasPermissions(VIEW_LOANS_REPORT, userPermissions) ||
      hasPermissions(VIEW_REPAYMENTS_REPORT, userPermissions) ||
      hasPermissions(VIEW_ACCOUNTS_REPORT, userPermissions) ? (
        <section className="reports-dropdown-wrapper">
          <button
            className={`reports ${
              location.pathname.split('/').includes('reports') ? 'active' : ''
            }`}
            data-testid="reports-menu"
          >
            Reports
          </button>

          <section className="reports-dropdown">
            {hasPermissions(VIEW_APPLICATIONS_REPORT, userPermissions) && (
              <NavLink to="/v1/reports" exact data-testid="application-menu">
                Applications
              </NavLink>
            )}
            {hasPermissions(VIEW_LOANS_REPORT, userPermissions) && (
              <NavLink to="/v1/reports/loans" exact data-testid="loans-menu">
                Loans
              </NavLink>
            )}
            {hasPermissions(VIEW_REPAYMENTS_REPORT, userPermissions) && (
              <NavLink
                to="/v1/reports/repayments"
                exact
                data-testid="repayments-menu"
              >
                Repayments
              </NavLink>
            )}
            {hasPermissions(VIEW_ACCOUNTS_REPORT, userPermissions) && (
              <NavLink
                to="/v1/reports/accounts"
                exact
                data-testid="accounts-menu"
              >
                Accounts
              </NavLink>
            )}
          </section>
        </section>
      ) : null}

      <a
        href="https://indicina.zendesk.com/hc/en-us"
        data-testid="guides-menu"
        target="_blank"
        rel="noreferrer"
        className="guides-link"
      >
        Guides <ExternalIcon />
      </a>
    </nav>
  );
};

export default HeaderNavs;
