import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { Fragment } from 'react';
import { BreadCrumbSection, Loader } from '../../components';
import ReportsNavbar from './components/ReportsNavbar/ReportsNavbar';
import './_Reports.scss';

const GET_REPAYMENTS_REPORT_URL = gql`
  query MP_GetRepaymentsReportUrl {
    getRepaymentsReportUrl {
      iframeUrl
      dashboard
    }
  }
`;

const MetabaseRepaymentsReport = () => {
  const { data: repaymentsReportData, loading: loadingRepaymentsReport } =
    useQuery(GET_REPAYMENTS_REPORT_URL);

  return (
    <Fragment>
      <BreadCrumbSection />
      <ReportsNavbar />

      {loadingRepaymentsReport && <Loader />}

      <div className="metabase-wrapper">
        {repaymentsReportData && (
          <iframe
            className="iframe"
            src={repaymentsReportData?.getRepaymentsReportUrl?.iframeUrl}
            frameBorder="0"
            width="100%"
            height="600px"
          />
        )}
      </div>
    </Fragment>
  );
};

export default MetabaseRepaymentsReport;
