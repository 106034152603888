import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumbSection, Loader } from '../../components';
import { ProfileIcon, PortfolioIcon, ApiIcon } from '../../components/Icons';
import config from '../../config/config';
import { useClientInfo } from '../../Providers-new/Client';

import './_Settings.scss';
import { Box } from '@chakra-ui/react';

const Settings = () => {
  const data = [
    {
      icon: 'profile',
      title: 'Users',
      details:
        'Create, edit and manage users. Give your users different access levels.',
      link: '/settings/user-management',
      isProd: true,
    },
    {
      icon: 'portfolio',
      title: 'Roles',
      details: 'Create, edit and manage user roles in your application.',
      link: '/settings/roles',
      isProd: true,
    },
    {
      icon: 'portfolio',
      title: 'Approval Workflow',
      details:
        'Select the type of approval workflow for your all your policies.',
      link: '/settings/approval-workflows',
      isProd: true,
    },
    {
      icon: 'profile',
      title: 'Edit Profile',
      details:
        'Make edits to your own profile. Edit your first name, last name and edit your password.',
      link: '/settings/edit-user',
      isProd: true,
    },
    {
      icon: 'api',
      title: 'API Keys & Webhooks',
      details: 'Generate new keys to use in live and test mode for deployment.',
      link: '/settings/api-keys-webhooks',
      isProd: true,
    },
    {
      icon: 'profile',
      title: 'Configuration',
      details:
        'Create, edit and manage users. Give your users different access levels',
      link: '/settings/configurations',
      isProd: true,
    },
    {
      icon: 'profile',
      title: 'KYC Configuration',
      details:
        'Create, edit and manage customer kyc information required during signup ',
      link: '/settings/kyc-configurations',
      isProd: true,
    },
  ];

  const { loading: ciLoading } = useClientInfo({
    privateFields: true,
  });

  return (
    <Fragment>
      <BreadCrumbSection />

      <div className="form-card settings">
        <div className={'settings__wrapper'}>
          {data &&
            data.map((item, key) => (
              <Fragment key={key}>
                {item.icon === 'api' ? (
                  <Fragment>
                    <Box
                      className={'settings__card'}
                      display={item.isProd ? 'block' : 'none'}
                    >
                      <Link key={`${key}-${item.title}`} to={item.link}>
                        {ciLoading && <Loader loadingText="loading..." />}
                        <ApiIcon />
                        <div className={'title'}>{item.title}</div>
                        <div className={'details'}>{item.details}</div>
                      </Link>
                    </Box>
                  </Fragment>
                ) : (
                  <Box
                    className={'settings__card'}
                    display={item.isProd ? 'block' : 'none'}
                  >
                    <Link key={`${key}-${item.title}`} to={item.link}>
                      {item.icon === 'profile' ? (
                        <ProfileIcon />
                      ) : (
                        <PortfolioIcon />
                      )}
                      <div className={'title'}>{item.title}</div>
                      <div className={'details'}>{item.details}</div>
                    </Link>
                  </Box>
                )}
              </Fragment>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Settings;
