import React from 'react';
import { createRoot } from 'react-dom/client';

import { datadogRum } from '@datadog/browser-rum';
import config from './config/config';
import { App } from './containers';
import ApolloRequestClient from './lib/ApolloRequestClient';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withClearCache from './ClearCache';

if (config.web.app_portal === 'live') {
  datadogRum.init({
    applicationId: 'b538e1bd-cb09-4cf9-8091-3f4f8accdb51',
    clientToken: 'pub6f8c5256a14f94e45e510c174adb0e61',
    site: 'datadoghq.eu',
    service: 'originate-mp',
    // Specify a version number to identify the deployed version of your application in Datadog.
    version: config.web.version,
    env: `OriginateMP_${config.web.app_env}`,
    sampleRate: 20,
    replaySampleRate: 20,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

const ClearCacheComponent = withClearCache(App);

const root = createRoot(document.getElementById('root'));

root.render(
  <ApolloRequestClient>
    <ToastContainer
      position="top-right"
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="colored"
    />
    <ClearCacheComponent />
  </ApolloRequestClient>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
